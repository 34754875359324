import * as React from "react";
import { Link } from "react-router-dom"; // Importar Link desde react-router-dom
import Header from "../components/Header";
import '../sass/styles.sass';

interface ButtonProps {
  icon: string;
  text: string;
  url:string;
}

const Button: React.FC<ButtonProps> = ({ icon, text, url }) => (
  url === "./Login.tsx" ? (
    <Link to={url} className="button">
      <img src={icon} alt="" className="button-icon" />
      <div className="button-text">{text}</div>
    </Link>
  ) : (
    <a href={url} className="button">
      <img src={icon} alt="" className="button-icon" />
      <div className="button-text">{text}</div>
    </a>
  )
);

const Landing: React.FC = () => {
  const buttons = [
    { icon: "./media/icons/login.svg", text: "Access using Kukun web", url:"/login" },
    { icon: "./media/icons/whatsapp-logo.svg", text: "Access using WhatsApp", url:"https://wa.me/522216670878/?text=%C2%A1Hola%20Kukun%21" },
    { icon: "./media/icons/external-link-outline.svg", text: "Access with Kigo App", url:"https://parkimovil.com/app" },
  ];

  return (
    <>
      <main className="container">
        <div className="content-wrapper">
          <Header />
          <section className="button-container">
            <h1 className="title">Welcome,</h1>
            <p className="subtitle">Select the desired option:</p>
            {buttons.map((button, index) => (
              <Button key={index} icon={button.icon} text={button.text} url={button.url} />
            ))}
          </section>
        </div>
      </main>
    </>
  );
};

export default Landing;