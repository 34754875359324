import React, { useState } from "react";
import '../sass/styles.sass';
import Header from "../components/Header";
import 'bootstrap/dist/css/bootstrap.min.css';
import NavigationBar from "../components/NavBar";
import AccessPIN from "../components/AccessPin";
import SmartAccessList from "../components/SmartAccessList";


const AuthorizedAccess: React.FC = () => {
  const [activeSection, setActiveSection] = useState("smart-access");
  const handleSectionChange = (section: string) => {
    setActiveSection(section);
  };

  return (
    <main className="container">
      <div className="content-wrapper">
        <Header />
        <div className="button-container">
          <h1 className="authorized-access-title">Authorized Access</h1>
        <div>
          <NavigationBar handleSectionChange={handleSectionChange} />
          </div>
          {activeSection === "smart-access" ? <SmartAccessList /> : <AccessPIN />}
          {/* {activeSection === "smart-access" ||"whatsApp-message" || "kigo-app" ? <SmartAccessList /> : <AccessPIN />} */}
        </div>
      </div>
    </main>
  );
};

export default AuthorizedAccess;
