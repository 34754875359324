import Cookies from "js-cookie";
import * as React from "react";
import { useEffect, useState } from "react";
import { GatesRequest, Doors, AccessModel } from "../core/_models";
import Swal from "sweetalert2";
import { authorizedAccessRequest, openRequest } from "../core/_requests";
import { faUnlock, faLock, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


interface DoorAccessProps {
  doorName: string;
  doorAlias: string;
  qr: string;
}

const DoorAccess: React.FC<DoorAccessProps> = ({ doorName, doorAlias, qr }) => {
  const [isPressed, setIsPressed] = useState(false);
  const [isLoading, setIsLoading] = useState(false)
  const [timer, setTimer] = useState<number | null>(null);

  const [areaCode, setAreaCode] = useState<string>('');
  const [phone, setPhone] = useState<string>('');

  useEffect(() => {
    const cookiesAreaCode = Cookies.get('areaCode');
    const cookiesPhone = Cookies.get('phone');
    const areaCodeFromSession = sessionStorage.getItem('areaCode');
    const phoneFromSession = sessionStorage.getItem('phone');

    setAreaCode(areaCodeFromSession || cookiesAreaCode || '');
    setPhone(phoneFromSession || cookiesPhone || '');
  }, []);

  const handleInteractionStart = () => {
    setIsPressed(true);
    setTimer(window.setTimeout(() => {
      setIsLoading(true);
      openDoor(qr);
    }, 3000));
  };

  const handleTouchEnd = () => {
    setIsPressed(false);
    if (timer) {
      clearTimeout(timer);
      setIsPressed(false)
    }
  }

  const handleTouchCancel = () => {
    setIsPressed(false);
    if (timer) {
      clearTimeout(timer);
      setIsPressed(false)
      setIsLoading(false);
    }
  }

  const handleMouseUp = () => {
    setIsPressed(false);
    if (timer) {
      clearTimeout(timer);
      setIsLoading(false);
    }
  }

  const openDoor = async (qr:string) => {
    try {
      const accessData: AccessModel = {
        qr,
        function: "open",
        cell: phone,
        firstName: "Visita",
        lastName: "Whatsapp",
        lada: areaCode,
        latitud: "0",
        longitud: "0"
      };

      const response = await openRequest(accessData);
      if (response){
        Swal.fire({
          icon: 'success',
          title: 'Door Unlocked',
          text: doorName + ' unlocked successfully!',
          timer: 10000,
          confirmButtonText: "Got it!",
        confirmButtonColor: "#ff5a00",
        }).then(() => {
          setIsLoading(false);
          setIsPressed(false)
        });
      }
      console.log(response); 
    } catch (error) {
      setIsLoading(false);
      console.error("Error opening the door:", error);
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'There was an error opening the door!',
        confirmButtonText: "Got it!",
        confirmButtonColor: "#ff5a00",
      });
    }
  };

  return (
    <button className={`door-access ${isPressed || isLoading ? 'door-access-pressed' : 'door-access'}`}
      onTouchStart={handleInteractionStart}
      onTouchEnd={handleTouchEnd}
      onTouchCancel={handleTouchCancel}
      onMouseUp={handleMouseUp}
      onMouseDown={handleInteractionStart}>
      <div className="door-access-content">
      <div>
          <div className={`door-image ${isPressed || isLoading ? 'door-image-pressed' : ''}`}>
          <FontAwesomeIcon icon={isLoading ? faSpinner : (isPressed ? faUnlock : faLock)} spin={isLoading} className={`fa ${isPressed && !isLoading ? 'fa-unlock-alt' : 'fa-lock'} fa-lg`} />
          </div>
          <div className="door-info">
            <div className={`door-name ${isPressed || isLoading ? 'door-name-pressed' : 'door-name'}`}>{doorName}</div>
            <div className={`door-alias ${isPressed || isLoading ? 'door-alias-pressed' : ''}`}>{doorAlias}</div>
          </div>
        </div>
      </div>
    </button>
  );
};

const SmartAccessList: React.FC = () => {
  const [doorAccessData, setDoorAccessData] = React.useState<Doors["accesos"]>(
    []
  );

  useEffect(() => {
    
    const fetchAuthorizedAccess = async () => {
      try {
        const cookiesAreaCode = Cookies.get('areaCode');
        const cookiesPhone = Cookies.get('phone');
        const areaCodeFromSession = sessionStorage.getItem('areaCode');
        const phoneFromSession = sessionStorage.getItem('phone');

        const accessData: GatesRequest = {
          plaza: "EC",
          cell: phoneFromSession || cookiesPhone || '',
          lada: areaCodeFromSession || cookiesAreaCode || '',
        };

        const response = await authorizedAccessRequest(accessData);
        setDoorAccessData(response.data.accesos);
      } catch (error) {
        console.error("Error fetching authorized access:", error);
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'There was an error fetching authorized access!',
          confirmButtonText: "Got it!",
          confirmButtonColor: "#ff5a00",
        });
      }
    };
    fetchAuthorizedAccess();
  }, []);

  return (
    <>
        <div className="add-margin-top"> 
        <p className="subtitle">
                Hold to unlock the selected door.
              </p>
            <div className="door-access-list">
              <div className="door-access-row">
                {doorAccessData.map((doorAccess, index) => (
                  <div key={index} className="door-access-column">
                    <DoorAccess
                      doorName={doorAccess.name}
                      doorAlias={doorAccess.alias}
                      qr={doorAccess.qr}
                    />
                  </div>
                ))}
              </div>
            </div>
        </div>
    </>
  );
};

export default SmartAccessList;