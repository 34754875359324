import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { AccessModel, AuthModel, AuthResponse, Doors, GatesRequest, OpenResponse, TwoFactor, UserModel } from "./_models";

const AUTH_API_URL = process.env.KIGO_APP_API_URL;
const KUKUN_API_URL = process.env.GEOSEK_KUKUN_API_URL
export const KUKUN_AUTHORIZED_ACCESS = `${KUKUN_API_URL}/gatesWA`;
export const KUKUN_OPEN_REQUEST = `${KUKUN_API_URL}/fakeIndexWhatsapp`;
export const LOGIN_URL = `${AUTH_API_URL}/phone-login`;
export const TWO_FACTOR = `${AUTH_API_URL}/verify-login?lat=0.00000&lng=0.00000`;
export const GEOSEK_AUTHORIZATION = process.env.GEOSEK_AUTH;
export const API_KEY = process.env.KIGO_APP_API_KEY;
const username = 'parkimovil_whatsapp_kukum';
const password = '877bd7ce6addd5e813fe92f1aed2dea901570468';
export const auth = 'Basic cGFya2ltb3ZpbF93aGF0c2FwcF9rdWt1bTo4NzdiZDdjZTZhZGRkNWU4MTNmZTkyZjFhZWQyZGVhOTAxNTcwNDY4'
export const base64Credentials = btoa(`${username}:${password}`);
export const authHeader = `Basic ${base64Credentials}`

export const loginRequest = async (formData: AuthModel): Promise<AxiosResponse<AuthResponse>> => {
  try {
    const config: AxiosRequestConfig = {
      method: 'post',
      maxBodyLength: Infinity,
      url: 'https://legacy.parkimovil.io/m/v1/users/phone-login',
      headers: { 
        'Content-Type': 'application/json',
        'Accept': '*/*',
        'x-token': '', 
        'x-api-key': 'WiZPDQyTr49585pDSrY9p1weQmD4fvSK55251ZVL', 
        'x-platform': 'gms', 
        'x-version': '6002025', 
        'x-country': 'mx', 
        'x-client': '', 
      },
      data: formData,
      responseType: 'json',
    }
    const response = await axios.request<AuthResponse>(config);
    return response;
  } catch (error) {
    throw error;
  }
};


export const confirmCodeRequest = async (formData: TwoFactor): Promise<AxiosResponse<UserModel>> => {
  try {
    const config: AxiosRequestConfig = {
      method: 'post',
      maxBodyLength: Infinity,
      url: 'https://legacy.parkimovil.io/m/v1/users/verify-login?lat=0.00000&lng=0.00000',
      headers: { 
        'Accept': '*/*',
        'Content-Type': 'application/json',
        'x-token': '', 
        'x-api-key': 'WiZPDQyTr49585pDSrY9p1weQmD4fvSK55251ZVL', 
        'x-platform': 'gms', 
        'x-version': '6002025', 
        'x-country': 'mx', 
      },
      data: JSON.stringify(formData),
      responseType: 'json',
    }
    const response = await axios.request<UserModel>(config);
    return response;
    } catch (error) {
      throw error;
    }
};

export const authorizedAccessRequest = async (accessData: GatesRequest): Promise<AxiosResponse<Doors>> => {
  try{
    const config: AxiosRequestConfig = {
      method: 'post',
      // url: 'https://geosek.com/api/geo/gatesWA',
      // url: 'https://geosek.com/api/geo/gatesWAak',
      url: 'https://39d610dccc1bff06bc2460884af3064e.m.pipedream.net',
      //url: 'https://ajew2pfz6s.us-west-2.awsapprunner.com/api/geo/gatesWA',
      data: JSON.stringify(accessData),
      responseType: 'json',
      headers: {
        'Content-Type': 'application/json', 
        'x-api-key': '07339135ef254b90b514621134c3eaf9',
        // 'Authorization': 'Basic cGFya2ltb3ZpbF93aGF0c2FwcF9rdWt1bTo4NzdiZDdjZTZhZGRkNWU4MTNmZTkyZjFhZWQyZGVhOTAxNTcwNDY4',
        },
  } 
  const response = await axios.request<Doors>(config);
  return response;
  }catch (error) {
    throw error;
  }
};

export const openRequest = async (gateData: AccessModel): Promise<AxiosResponse<OpenResponse>> => {
  try{
    const config: AxiosRequestConfig = {
      method: 'post',
      maxBodyLength: Infinity,
      // url: 'https://geosek.com/api/geo/fakeIndexWhatsapp',
      url: 'https://ajew2pfz6s.us-west-2.awsapprunner.com/api/geo/fakeIndexWhatsapp',
      headers: {
        // 'Authorization': 'Basic cGFya2ltb3ZpbF93aGF0c2FwcF9rdWt1bTo4NzdiZDdjZTZhZGRkNWU4MTNmZTkyZjFhZWQyZGVhOTAxNTcwNDY4',
        'Content-Type': 'text/plain', 
        'Accept': '*/*',
      },
      data: gateData,
      responseType: 'json',
  } 
  const response = await axios.request<OpenResponse>(config);
  return response;
  }catch (error) {
    throw error;
  }
};