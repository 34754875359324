import Navbar from 'react-bootstrap/Navbar';
import { Container, Nav } from 'react-bootstrap';
import React, { useState } from 'react';
import '../sass/styles.sass';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLink, faKey, faWifi } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

const NavigationBar: React.FC<{ handleSectionChange: (section: string) => void }> = ({ handleSectionChange }) => {
  const [selectedSection, setSelectedSection] = useState<string>('smart-access');
  const handleSelect = (section: string) => {
    setSelectedSection(section);
    handleSectionChange(section);
  };
  return (
    <Navbar bg="orange" expand="lg" id='nav-bar'>
      <Container className='nav-bar-container'>
      <Navbar.Toggle aria-controls="basic-navbar-nav" className='nav-bar-toggler'/>
      <Navbar.Collapse id="basic-navbar-nav" className='nav-bar-toggler'>
        <Nav className="mx-auto nav-bar-collapse">
          <Nav className={` nav-bar-text nav-bar-elements nav-link ${selectedSection === 'smart-access' ? 'active' : ''}`} onClick={() => handleSelect('smart-access')}>
            <FontAwesomeIcon icon={faWifi} style={{ marginRight: '10px' }} />
            Smart Access
          </Nav>
          <Nav className={`nav-bar-text nav-bar-elements nav-link ${selectedSection === 'generate-pin' ? 'active' : ''}`} onClick={() => handleSelect('generate-pin')}>
            <FontAwesomeIcon icon={faKey} style={{ marginRight: '10px', alignSelf: 'center' }} />
            Generate PIN
          </Nav>
          {/* <Nav.Link href="https://wa.me/522216670878/?text=%C2%A1Hola%20Kukun%21" className={`nav-bar-text nav-bar-elements nav-link ${selectedSection === 'whatsApp-message' ? '' : ''}`}>
            <FontAwesomeIcon icon={faWhatsapp} style={{ marginRight: '10px' }} />
            Access using WhatsApp
          </Nav.Link>
          <Nav.Link href="https://parkimovil.com/app" className={`nav-bar-text nav-bar-elements nav-link ${selectedSection === 'kigo-app' ? 'active' : ''}`} onClick={() => handleSelect('kigo-app')}>
          <img
              src="/media/icons/kigo.svg"
              width="16"
              height="16"
              color="#3a3a3a"
              alt="Kigo App logo"
            />
            Access using Kigo
          </Nav.Link> */}
        </Nav>
      </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
export default NavigationBar;
