import React, { useState } from "react";
import '../sass/styles.sass'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { loginRequest } from "../core/_requests";
import Swal from 'sweetalert2';
import { AuthModel } from "../core/_models";
import Cookies from 'js-cookie';


const loginSchema = Yup.object().shape({
  areaCode: Yup.string()
    .required('Area code is required'),
  phone: Yup.string()
    .required('Phone number is required')
    .min(9, 'Enter at least 9 digits')
    .max(15, 'Enter maximum 15 digits'),
  useWhatsapp: Yup.number()
    .required('Please select a messaging option')
    .oneOf([0, 1], 'Invalid messaging option'),
});

const initialValues: AuthModel = {
  areaCode: '',
  phone: '',
  useWhatsapp: 1 // Valor por default indicando usar WA
};


const LoginForm: React.FC = () => {
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (values: AuthModel) => {
    setIsSubmitting(true);
    try {
      const response = await loginRequest(values); 
      if (response.data.success) {
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Code sent successfully.',
          confirmButtonColor: "#ff5a00",
        });
        Cookies.set('areaCode', values.areaCode);
        Cookies.set('phone', values.phone);
        sessionStorage.setItem('phone', values.phone)
        sessionStorage.setItem('areaCode', values.areaCode)
        navigate('/two-factor'); 
      } else if(response.status===429){
        Swal.fire({
          icon: 'error',
          title: 'Too many tries',
          text: 'Try again in 3 minutes.',
          confirmButtonColor: "#ff5a00",
        });
      } else if(response.status===404){
        Swal.fire({
          icon: 'error',
          title: 'Code expired',
          text: 'The code sent has expired or is invalid',
          confirmButtonColor: "#ff5a00",
        });
      }
    } catch (error) {
      console.error('Error:', error);
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'An error occurred! Please try again later.',
        confirmButtonColor: "#ff5a00",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div>
      <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={loginSchema}>
        {({ values, setFieldValue }) => (
          <Form className="button-container">
            <h1 className="form-title">Sign In</h1>
            <p className="subtitle">Enter your phone number</p>
            <Field
              as="select"
              id="areaCode"
              name="areaCode"
              className="form-input"
              aria-label="AreaCode"
            >
              <option value="">Select Area Code</option>
              <option value="1">+1 USA</option>
              <option value="52">+52 MX</option>
              {/* Consultar principales países a añadir para versión simplificada */}
            </Field>
            <ErrorMessage name="areaCode" component="div" className="error-message" />
            <Field
              as="input"
              type="tel"
              id="phone"
              name="phone"
              className="form-input"
              placeholder="Phone Number"
              aria-label="Phone"
              autoComplete="on"
            />
            <ErrorMessage name="phone" component="div" className="error-message" />
            <div className="send-code-text">
              <label>
                <input 
                  type="radio" 
                  name="useWhatsapp" 
                  value={1} 
                  checked={values.useWhatsapp === 1}
                  onChange={() => setFieldValue("useWhatsapp", 1)}
                />
                ⠀Send code using WhatsApp
              </label>
            </div>
            <div className="send-code-text">
              <label>
                <input 
                  type="radio" 
                  name="useWhatsapp" 
                  value={0} 
                  checked={values.useWhatsapp === 0}
                  onChange={() => setFieldValue("useWhatsapp", 0)}
                />
                ⠀Send code using SMS
              </label>
            </div>
            <ErrorMessage name="useWhatsapp" component="div" className="error-message" />
            <button type="submit" className="form-button" disabled={isSubmitting}>
              <div className="form-button-text">
              {isSubmitting ? (
                  <>
                    <span className="sending-code">Sending code...<div className="loader"></div></span>
                  </>
                ) : (
                  <span>Send code</span>
                )}
              </div>
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default LoginForm;