import * as React from "react";
import '../sass/styles.sass';
import { TwoFactor } from "../core/_models";
import { confirmCodeRequest } from "../core/_requests";
import { Formik, Form, Field } from "formik";
import * as Yup from 'yup';
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";

const TwoFactorSchema = Yup.object().shape({
  code: Yup.string()
   .required('5-digit code is required')
   .length(5, 'Your code must be a 5-digit code')
});

// const TwoFactorinitialValues: TwoFactor = {
//   areaCode: areaCode,
//   phone: phone,
//   code: '',
// };

const TwoFactorInput: React.FC = () => {
  const navigate = useNavigate();

  const [areaCode, setAreaCode] = useState<string>('');
  const [phone, setPhone] = useState<string>('');

  useEffect(() => {
    const cookiesAreaCode = Cookies.get('areaCode');
    const cookiesPhone = Cookies.get('phone');
    const areaCodeFromSession = sessionStorage.getItem('areaCode');
    const phoneFromSession = sessionStorage.getItem('phone');

    setAreaCode(areaCodeFromSession || cookiesAreaCode || '');
    setPhone(phoneFromSession || cookiesPhone || '');
  }, []);

  const [resendTimer, setResendTimer] = useState<number>(60);
  const [resendEnabled, setResendEnabled] = useState<boolean>(false);

  useEffect(() => {
    if (resendTimer > 0) {
      const timer = setTimeout(() => {
        setResendTimer(prevTimer => prevTimer - 1);
      }, 1000);
      return () => clearTimeout(timer);
    } else {
      setResendEnabled(true);
    }
  }, [resendTimer]);


  const handleSubmit = async (values: TwoFactor, { setSubmitting }: any) => {
    try {
      const response = await confirmCodeRequest(values);
      if (response.data.status) {
        Swal.fire ({
          icon: 'success',
          title: 'Login successful.',
          text: 'You have signed in successfully',
          timer: 10000,
          confirmButtonColor: "#ff5a00",
        }).then((result: any) => {
          if(result.isConfirmed){
            navigate('/authorized-access');
            const token = response.data.token;
            sessionStorage.setItem('token', token);
          }
        });
      } else if (response.data.status===404){
        Swal.fire({
          icon: 'error',
          title: 'Code expired',
          text: 'The code sent has expired or is invalid',
          confirmButtonColor: "#ff5a00",
        });
      }
    } catch (error) {
      console.error ('Error: ', error);
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'An error occurred! Please try again later.',
        confirmButtonColor: "#ff5a00",
      })
    } finally {
      setSubmitting(false);
    }
  };
 
  return (
    <div>
      <Formik initialValues={{
          areaCode: Cookies.get('areaCode') || sessionStorage.getItem('areaCode') || '',
          phone: Cookies.get('phone') || sessionStorage.getItem('phone') || '',
          code: '',
        }} onSubmit={handleSubmit} validationSchema={TwoFactorSchema}>
        {({ isSubmitting }) => (
          <Form className="button-container">
            <h1 className="form-title">Two-step Verification</h1>
            <p className="subtitle">
              Enter the 5-digit code we sent to the provided phone number
            </p>
            <Field
              type="text"
              id="code"
              name="code"
              maxLength={5}
              className="verification-code-input"
              placeholder=" *****"
              aria-label="Code"
              autoComplete="off"
            />
              <div className="visually-hidden">
                <input type="tel" id="phone" defaultValue={phone}>
                </input>
                <input type="text" id="areaCode" defaultValue={areaCode}/>
              </div>
              <button type="submit" className="form-button" disabled={isSubmitting}>
              <div className="form-button-text">
              {isSubmitting ? (
                  <>
                    <span className="sending-code">Signing In...<div className="loader"></div></span>
                  </>
                ) : (
                  <span>Sign In</span>
                )}
              </div>
              </button>
              <p>
                {resendEnabled ? (
                  <Link to='/login' className="send-code-text">
                    Resend code
                  </Link>
                ) : (
                  <span className="resend-code-text">Resend code in {resendTimer} seconds</span>
                )}
              </p>
          </Form>
        )}
      </Formik>
    </div>
  );
};
export default TwoFactorInput;
