import React from "react";
import '../sass/styles.sass';


export default function Header() {
interface ImageWrapperProps {
    src: string;
    alt: string;
    className: string;
  }
  
  const ImageWrapper: React.FC<ImageWrapperProps> = ({ src, alt, className }) => (
    <img src={src} alt={alt} className={className} />
  );
  return (
    <ImageWrapper
      src="./media/logo/kukun-light.svg"
      alt="Kukun logo"
      className="logo"
    />
  );
}
