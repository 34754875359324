import React from "react";
import Header from "../components/Header";
import '../sass/styles.sass';
import LoginForm from "../components/LoginForm";

const Login: React.FC = () => {
  
  return (
    <main className="container">
      <div className="content-wrapper">
        <Header />
        <LoginForm />
      </div>
    </main>
  );
};

export default Login;