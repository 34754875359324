import { Routes, Route, useNavigate } from "react-router-dom"
import React, { useEffect, useState } from "react"
import Landing from "./modules/Landing"
import Login from "./modules/Login"
import TwoFactorPage from "./modules/Two-Factor"
import AuthorizedAccess from "./modules/Authorized-Access"
import './sass/styles.sass'

function Main(){
    const navigate = useNavigate();

    useEffect(() => {
        const verifyToken = () => {
            const token = sessionStorage.getItem('token'); 
            if (token && window.location.pathname !== '/authorized-access') {
                navigate('/authorized-access');
            }
        };
        verifyToken();
    }, [navigate]);

    return(
        <div className="main">
            <Routes>
                <Route path="/" element={ <Landing /> } />
                <Route path="/login" element={ <Login /> } />
                <Route path="/two-factor" element={ <TwoFactorPage/> } />
                <Route path="/authorized-access" element={<AuthorizedAccess />} />
            </Routes>
        </div>
    )
}

// const PrivateRoute: React.FC<{ path: string; element: React.ReactNode }> = ({
//     path,
//     element,
//   }) => {
//     const token = localStorage.getItem('token');
  
//     return token ? (
//       <Route path={path} element={element} />
//     ) : (
//       <Navigate to="/login" replace />
//     );
//   };
  
export default Main