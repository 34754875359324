import { faLock, faUnlock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Cookies from "js-cookie";
import * as React from "react";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { Doors, GatesRequest } from "../core/_models";
import { authorizedAccessRequest } from "../core/_requests";


interface DoorAccessProps {
  doorName: string;
  doorAlias: string;
  doorPin: string;
}

const DoorAccess: React.FC<DoorAccessProps> = ({ doorName, doorAlias, doorPin }) => {
  const [isPressed, setIsPressed] = useState(false);
  const [timer, setTimer] = useState<number | null>(null);

  const [areaCode, setAreaCode] = useState<string>('');
  const [phone, setPhone] = useState<string>('');

  useEffect(() => {
    const cookiesAreaCode = Cookies.get('areaCode');
    const cookiesPhone = Cookies.get('phone');
    const areaCodeFromSession = sessionStorage.getItem('areaCode');
    const phoneFromSession = sessionStorage.getItem('phone');

    setAreaCode(areaCodeFromSession || cookiesAreaCode || '');
    setPhone(phoneFromSession || cookiesPhone || '');
  }, []);

  const handleInteractionStart = () => {
    setIsPressed(true);
    setTimer(window.setTimeout(() => {
      Swal.fire({
        icon: 'info',
        title: 'PIN Code for: \n' + doorName,
        text: doorPin ,
        confirmButtonText: "Got it!",
        confirmButtonColor: "#ff5a00",
        timer: 10000,
      }).then(() => {
        setIsPressed(false)
      });
    }, 3000));
  };

  const handleTouchEnd = () => {
    setIsPressed(false);
    if (timer) {
      clearTimeout(timer);
      setIsPressed(false)
    }
  }

  const handleMouseUp = () => {
    setIsPressed(false);
    if (timer) {
      clearTimeout(timer);
      setIsPressed(false)
    }
  }

  return (
    <button className={`door-access ${isPressed ? 'door-access-pressed' : 'door-access'}`}
      onTouchStart={handleInteractionStart}
      onTouchEnd={handleTouchEnd}
      onTouchCancel={handleTouchEnd} 
      onMouseDown={handleInteractionStart}
      onMouseUp={handleMouseUp}>
  
      <div className="door-access-content">
        <div >
          <div className={`door-image ${isPressed ? 'door-image-pressed' : ''}`}>
            <FontAwesomeIcon icon={isPressed ? faUnlock : faLock} className={`fa fa-lock fa-lg ${isPressed ? 'fa fa-unlock-alt fa-lg' : ''}`} />
          </div>  
          <div className="door-info">
            <div className={`door-name ${isPressed ? 'door-name-pressed' : 'door-name'}`}>{doorName}</div>
            <div className={`door-alias ${isPressed ? 'door-alias-pressed' : ''}`}>{doorAlias}</div>
          </div>
        </div>
      </div>
    </button>
  );
};

const GeneratePinList: React.FC = () => {
  const [doorAccessData, setDoorAccessData] = React.useState<Doors["accesos"]>(
    []
  );

  useEffect(() => {
  
    const fetchAuthorizedAccess = async () => {
      try {
        const cookiesAreaCode = Cookies.get('areaCode');
        const cookiesPhone = Cookies.get('phone');
        const areaCodeFromSession = sessionStorage.getItem('areaCode');
        const phoneFromSession = sessionStorage.getItem('phone');

        const accessData: GatesRequest = {
          plaza: "EC",
          cell: phoneFromSession || cookiesPhone || '',
          lada: areaCodeFromSession || cookiesAreaCode || '',
        };

        const response = await authorizedAccessRequest(accessData);
        setDoorAccessData(response.data.accesos);
      } catch (error) {
        console.error("Error fetching authorized access:", error);
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'There was an error fetching authorized access!',
        });
      }
    };
    fetchAuthorizedAccess();
  }, []);


  return (
    <>
        <div className="add-margin-top">
        {/* <NavBar /> */}
        <p className="subtitle">
                Hold to generate the PIN.
              </p>
            <div className="door-access-list">
              <div className="door-access-row">
                {doorAccessData.map((doorAccess, index) => (
                  <div key={index} className="door-access-column">
                    <DoorAccess
                      doorName={doorAccess.name}
                      doorAlias={doorAccess.alias}
                      doorPin={doorAccess.pin}
                    />
                  </div>
                ))}
              </div>
            </div>
        </div>
    </>
  );
};

export default GeneratePinList;