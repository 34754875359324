import * as React from "react";
import '../sass/styles.sass';
import Header from "../components/Header";
import TwoFactorInput from "../components/VerificationCodeInput";


const TwoFactorPage: React.FC = () => {

  return (
      <main className="container">
        <div className="content-wrapper">
          <Header />
          <TwoFactorInput />
          </div>
      </main>
  );
};

export default TwoFactorPage;